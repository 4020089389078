
import { mapState } from 'vuex'
import baseLogic from '~/mixins/base-logic'
export default {
    name: 'Theme3SwitchLanguages',
    mixins: [baseLogic],
    data() {
        return {}
    },
    computed: {
        ...mapState({
            languages: (state) => state.themes.settings.language,
            supportCountryAndLanguage: (state) =>
                state.settings.supportCountryAndLanguage,
        }),
    },
    methods: {
        isActiveLanguage(item) {
            return item?.supported_languages?.filter(
                (el) => el?.is_active || el?.is_default
            )
        },
    },
}
